<template>
  <div class="kb-form-fields">
    <div class="kb-form-row">
      <div class="kb-form-column kb-form-column-title">
        <label class="kb-form-label">
          <span class="kb-form-label-text">비밀번호 변경</span>
        </label>
      </div>
      <div class="kb-form-column">
        <input v-model="password" type="password" class="kb-form-control" placeholder="비밀번호를 입력하세요.">
      </div>
    </div>
    <!-- kb-form-row:비밀번호 재입력 -->
    <div class="kb-form-row">
      <div class="kb-form-column kb-form-column-title">
        <label class="kb-form-label">
          <span class="kb-form-label-text">비밀번호 재입력</span>
        </label>
      </div>
      <div class="kb-form-column">
        <input v-model="checkPassword" type="password" class="kb-form-control" placeholder="비밀번호를 재입력하세요.">
      </div>
    </div>
  </div>
  <div class="kb-form-fields-bottom">
    <p class="text text-notice"><strong class="text">비밀번호 변경 유의사항</strong>  길이 : 8자리 이상 / 구성 : 영문자, 숫자, 특수문자를 최소 1자리 이상 혼합 구성 / 사용금지 : 연속, 반복문자(4자리), 대표단어(kbstar, kbfng, kbfg, kbcard) 개인정보(주민번호, 연락처, 사번 등) / 재사용금지 : 최근 사용한 비밀번호</p>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {isSuccess, validatePassword} from "@/assets/js/util";
import {ACT_UPDATE_AUTH_PWD} from "@/store/modules/auth/auth";
import {useStore} from "vuex";
import {useRouter} from "vue-router/dist/vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: "PasswordForm",
  props: {
    lrnerId: String,
    changeEffect: Number,
    closeEffect: Number,
    pageType: String,
    closeModal: Function,
    authParams: Object
  },
  setup(props) {

    const store = useStore();
    const router = useRouter();

    const {showMessage} = useAlert();

    const password = ref('');
    const checkPassword = ref('');

    watch(() => props.changeEffect , () => {
      if(validatePassword(password.value, checkPassword.value)) {
        store.dispatch(`auth/${ACT_UPDATE_AUTH_PWD}`, {
          lrnerId: props.lrnerId,
          params: {
            password: password.value,
            certNo: props.authParams.certNo,
            certNoSn: props.authParams.certNoSn,
            lrnerId: props.authParams.lrnerId,
            brdt: props.authParams.brdt
          },
        }).then(res => {
          if(isSuccess(res)) {
            showMessage({
              text: '비밀번호가 변경되었습니다. 잘 기억하여 두시기 바랍니다.',
              callback: () => {
                if(props.pageType === 'reissue') {
                  router.push({name: 'Signin'});
                } else if(props.pageType === 'my') {
                  props.closeModal(false);
                }
              }
            });
          } else if(res.result.number === 301 || res.result.number === 400) {
            showMessage({
              text: res.result.message
            });
          }
        })
      }
    });


    watch(() => props.closeEffect, () => {
      password.value = '';
      checkPassword.value = '';
    });

    return {
      password,
      checkPassword
    }
  }
}
</script>