<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active' : modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-mypage-password">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">연수/ 외부용 비밀번호 변경</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <!-- kb-form-fields -->
            <PasswordForm
                          :lrner-id="lrnerId"
                          :change-effect="changeEffect"
                          :close-effect="closeEffect"
                          :close-modal="closeModal"
                          page-type="my"
                          :auth-params="authParams"

            />
            <!-- popup-inner > popup-buttons -->
            <div class="popup-buttons">
              <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
              <button class="kb-btn kb-btn-primary kb-btn-lg" @click="changePassword"><span class="text">변경</span></button>
            </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
    </div>
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import {ref} from "vue";
import PasswordForm from "@/components/my/PasswordForm";

export default {
  name: "MyPasswordChangeModal",
  components: {PasswordForm},
  props: {
    modelValue: Boolean,
    lrnerId: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {


    const changeEffect = ref(0);
    const closeEffect = ref(0);

    const authParams = ref({
      lrnerId: 'mypage',
      certNo: '0000',
      certNoSn: 0,
      brdt: '0000'
    });

    const closeModal = (close) => {
      console.log('close?', close)
      closeEffect.value++;
      emit('update:modelValue', false);
    }

    const changePassword = () => {
      changeEffect.value++;
    }

    return {
      changeEffect,
      closeEffect,
      authParams,
      changePassword,
      closeModal
    }
  }
  
}
</script>